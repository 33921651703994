import React, { useState, useEffect } from 'react';

import Layout from '../../../components/layout';
import Content from '../../../components/licensed-in/content-container';

export default () => {
  const [licensedInOpened, setLicensedInOpened] = useState(false);
  const [getAQuoteOpened, setGetAQuoteOpened] = useState(false);

  const props = {
    licensedInOpened,
    setLicensedInOpened,
    getAQuoteOpened,
    setGetAQuoteOpened
  };

  return (
    <Layout {...props} activePage='licensed-in' id='licensed-in-page'>
      <div className='licensed-in-page-container'>
        <Content title='COMPREHENSIVE INSURANCE FOR CALIFORNIANS' activeCard='california' image={'/California_BG.jpeg'}>
          <p>
            Apollo Insurance represents the following insurance providers in California: Aliera, National General, Kaiser Permanente, Oscar and Anthem Blue
            Cross and Blue Shield. These top-notch partners allow us to offer our insurance clients a wide mixture of Affordable Care Act Plans, Short Term
            Medical Plans, Share Ministry Plans, Off-exchange Policies, and more. Apollo Insurance understands that not all plans are created equal and have
            selected these vendors for their high value coverage at an affordable cost. We’re here to help you find the best California health care plans.{' '}
          </p>
          <p>
            Let us help you discover healthcare coverage plans you can count on at the lowest premiums in California. Apollo Insurance is dedicated to
            delivering peace of mind through medical benefits with increased security that is tailored to client need. We partner with the best California
            health plans to give you the best options available. Your medical challenges are simplified by our industry expertise shared with our knowledgeable
            advisors.{' '}
          </p>

          <p>
            We offer individual, family, disability, vision, dental, accident, critical illness, and gap insurance plans. With the increase in deductibles and
            out of pocket expenses of health insurance in Missouri shifting to the consumer, gap insurance plans have become the most important and popular plan
            we sell today.
          </p>

          <p>
            We know that your health is critical, and we want you to be aware of all your options. Contact us to get a free quote and explore the best
            California health care plans for you and your family.
          </p>

          <p>
            We offer individual, family, disability, vision, dental, accident, critical illness, and gap insurance plans. With the increase in deductibles and
            out of pocket expenses of health insurance shifting to the consumer, gap insurance plans have become the most important and popular plan we sell
            today.
          </p>
        </Content>
      </div>
    </Layout>
  );
};
